@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';
@use './fontAwesome.scss';

// Colors + overrides need to happen after tailwindcss because tailwind overrides a bunch of styles in 'preflight'
@use 'styles/gradient.scss';
@use 'styles/utils.css';

* {
  box-sizing: border-box;
}

svg {
  display: inline;
  vertical-align: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 500;
}

// Adding scroll-behavior: smooth; to `html` prevents the NextLink component
// from resetting scroll on page change. Apply only to `body` instead as a workaround.
body {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

form {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  // Compensate for navbar when scrolling
  scroll-margin-top: 92px;
}

em {
  font-style: normal !important; // override algolia that makes it italic and look super funny
  font-weight: bold;
}

.olark-button-focus-wrapper {
  margin-right: 30px; // override olark chat widget to have appropriate absolute position
}

// Sponsor page date picker css overide
.react-datepicker__day--outside-month {
  opacity: 0.4;
}

// react-phone-number-input css override
.PhoneInputInput:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.PhoneInputInput {
  background-color: transparent !important;
}

// Sendbird message UI customization
.sendbird-theme--light .sendbird-conversation {
  border: none !important;
}
.sendbird-conversation__footer {
  padding-bottom: 0 !important;
}

// Hacky way to add 'via SMS' to the message timestamp for messages sent via SMS
.ssp-sendbird-message-sent-via-sms {
  .sendbird-message-content__middle__body-container__created-at:after {
    margin-left: 4px;
    content: '· via SMS';
  }
  .sendbird-message-content__middle__body-container__created-at {
    right: -104px !important;
  }
}
.inbox-sendbird-message-sent-via-sms {
  .sendbird-label--caption-3:after {
    margin-left: 4px;
    content: '· via SMS';
  }
  .sendbird-message-content__middle__body-container__created-at {
    left: -124px !important;
  }
}

@media (max-width: 640px) {
  .sendbird-message-content__middle__body-container__created-at {
    position: static !important;
    left: auto !important;
    bottom: auto !important;
    min-width: auto !important;
  }
}

// Based on https://stackoverflow.com/questions/23772673/hidden-property-does-not-work-with-flex-box
// Ensure elements with the hidden attribute are always hidden,
// irrespective of the element's assigned display property. Takes precedence over media queries too.
[hidden] {
  display: none !important;
}
