@tailwind utilities;

@layer utilities {
  .hide-scrollbars::-webkit-scrollbar {
    /* Webkit */
    display: none;
  }
  .hide-scrollbars {
    /* Firefox */
    scrollbar-width: none;
  }
  /* Chrome + Firefox hint to improve INP https://web.dev/articles/optimize-inp#use_content-visibility_to_lazily_render_off-screen_elements */
  .content-visibility-auto {
    content-visibility: auto;
  }

  .minimal-scrollbar::-webkit-scrollbar {
    width: 20px;
  }

  .minimal-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .show-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  .show-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .minimal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  .minimal-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #9ca3af;
  }

  #HorezontalTableOfContents::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 100%
    );
  }

  /* 
   * Accessibility - Increase touch target size without affecting layout
   * This class uses a combination of pseudo-element and pointer-events
   * to create an invisible touch area that's at least 44x44px
   * while still allowing hover states to work correctly
   */
  .a11y-touch-target {
    position: relative;
  }

  .a11y-touch-target::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    min-width: 44px; /* Minimum recommended touch target width */
    min-height: 44px; /* Minimum recommended touch target height */
    z-index: 1; /* Ensure it's above other elements */
  }

  /* The actual element content should be positioned above the touch target */
  .a11y-touch-target > * {
    position: relative;
    z-index: 2; /* Place content above the touch target */
  }

  /* 
   * Fixed-size touch target - For precise components that must maintain exact dimensions
   * Use on parent container with size-X class and child with this class
   */
  .a11y-fixed-size-touch-target {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .a11y-fixed-size-touch-target::before {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Ensure it doesn't affect layout */
    pointer-events: auto;
    z-index: 1;
  }
}
