// NOTE: following gradients are not relying on $brandColors since they have their own figma definition
// https://www.figma.com/file/EDhXcdjFZ0FObQfixx20mC/Power-Colors?node-id=23%3A448

$organic: (
  hiringCta: (
    linear-gradient(115deg, rgba(250, 161, 207, 0.60) -5.14%, rgba(250, 161, 207, 0.00) 36.54%),
    radial-gradient(314.58% 70.01% at 95.58% 131.45%, rgba(129, 225, 255, 0.60) 0%, rgba(161, 232, 255, 0.00) 100%), radial-gradient(97.85% 93.69% at 44.56% 73.39%, rgba(228, 218, 255, 0.60) 0%, rgba(228, 218, 255, 0.00) 100%), radial-gradient(170.06% 112.77% at 73.13% 25.81%, rgba(255, 197, 51, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), #ffffff,
  ),
  organicWarm: (
    radial-gradient(
      60.61% 235.48% at 93.27% 50%,
      rgba(252, 165, 165, 0.2) 0%,
      rgba(252, 165, 165, 0) 100%
    ),
    linear-gradient(
      259.06deg,
      rgba(129, 225, 255, 0.2) -92.97%,
      rgba(129, 225, 255, 0) 58.66%
    ),
    radial-gradient(
      49.39% 76.62% at 55.57% 100%,
      rgba(160, 229, 72, 0.2) 0%,
      rgba(231, 248, 209, 0.2) 100%
    ),
    linear-gradient(101.72deg, #ffc533 -28.22%, #fffbf0 100%),
  ),
  organicPeach: (
    linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%),
    radial-gradient(
      194.07% 133.7% at 33.76% -32.71%,
      rgba(201, 181, 255, 0.36) 0%,
      rgba(244, 240, 255, 0.36) 100%
    ),
    radial-gradient(
      195.1% 108.58% at 92.24% 23.19%,
      rgba(255, 197, 51, 0.60) 9.2%,
      rgba(255, 226, 153, 0.00) 47.92%,
      rgba(255, 194, 225, 0.60) 100%
    ),
    linear-gradient(196deg, #7747FF -4.26%, #F283BD 19.08%, #FFF0F8 88.6%),
  ),
  organicDawn: (
    linear-gradient(
      99.32deg,
      rgba(255, 197, 51, 0) 80.53%,
      rgba(255, 197, 51, 0.2) 88.36%
    ),
    radial-gradient(
      20.58% 43.99% at 5.31% 65.13%,
      rgba(255, 182, 0, 0.2) 0%,
      rgba(255, 182, 0, 0) 100%
    ),
    radial-gradient(
      85.97% 248.29% at 24.53% -26.81%,
      #2d117b 0%,
      #7747ff 38.57%,
      #926cff 51.99%,
      #ffb3da 88.02%
    ),
  ),
  organicEczema: (
    radial-gradient(
      27.29% 60.19% at 72.15% 89.66%,
      rgba(255, 182, 0, 0.4) 0%,
      rgba(217, 119, 6, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(250, 161, 207, 0) 0%,
      rgba(250, 161, 207, 0.4) 100%
    ),
    linear-gradient(165.14deg, #ffd366 4.85%, #f0fdf4 89.52%),
  ),
  organicSunrise: (
    radial-gradient(
      50% 50% at 50% 50%,
      rgb(242, 173, 0, 1) 0%,
      rgba(255, 211, 102, 0) 100%
    ),
  ),
  sspHeroGradient: (
    radial-gradient(
      40% 100% at 15% 50%,
      rgba(105, 58, 242, 0.3) 15%,
      transparent 60%
    ),
    radial-gradient(
      circle at 30% 95%,
      rgba(255, 240, 204, 0.4) 8%,
      transparent 45%
    ),
    radial-gradient(
      circle at 25% 5%,
      rgba(255, 209, 233, 0.4) 8%,
      transparent 45%
    ),
  ),
);

$linear: (
  linearWarm: linear-gradient(98.41deg, #ff5f6d 0%, #ffc371 100%),
  linearPrimary: linear-gradient(103.94deg, #926cff 6.94%, #faa1cf 100%),
  linearDarkGarden: linear-gradient(98.41deg, #c22376 0%, #00a299 100%),
  linearDarkCool: linear-gradient(98.03deg, #2381ff -15.29%, #ac63c4 107.57%),
  footerPurple: linear-gradient(90deg, #7747ff 0%, #926cff 100%),
);

@function organic($gradientName) {
  @return map-get($organic, $gradientName);
}

@function linear($gradientName) {
  @return map-get($linear, $gradientName);
}

// Create gradient utility classes like .gradient-darkGarden
@each $gradient, $value in $linear {
  .bg-#{$gradient} {
    background: $value;
  }

  .bg-#{$gradient}-grainy {
    background: $value,
      url('https://imagedelivery.net/BywPZfhqd_-_A1zCYqpnww/14eb68c7-abeb-4797-fe35-227e0f10bf00/public');
    background-blend-mode: screen;
    background-repeat: no-repeat, repeat;
  }
}
@each $gradient, $value in $organic {
  .bg-#{$gradient} {
    background: $value;
  }

  .bg-#{$gradient}-grainy {
    background: $value,
      url('https://imagedelivery.net/BywPZfhqd_-_A1zCYqpnww/5bfdd09b-0cdb-4037-4349-de143dddea00/public');
    background-blend-mode: screen;
    background-repeat: no-repeat, repeat;
  }
}
